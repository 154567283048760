<template>
    <layout class="wrapper">
        <!-- <div class="flex" style="margin: 0 auto;width: 400px;">
            <a-input style="width:200px;" placeholder="模板id"  v-model="value1" @change="change" />
            <div style="background: #eeeeee;width:200px;" class="pt5 pb5 pl10 pr10 mt10" @click="commitXcx">三方小程序上传体验版</div>
        </div> -->
        <div class="container flex x-between mt40">
            <div class="journal-article-left">
                <a-spin :spinning="loading" tip="Loading...">
                    <!-- 期刊约稿 -->
                    <approximations v-if="approximationList.length" :approximationList="approximationList" :imgList="imgList"></approximations>
                    <!-- 推荐期刊 -->
                    <div class="mt40 journal-list" v-if="journalItemList.length">
                        <common-title title="推荐期刊" class="journal-titles" path="/periodical-list"></common-title>
                        <div class="flex flex-wrap">
                            <div class="flex jounal-items" v-for="(item, index) in journalItemList" :key="index">
                                <journal-item path="/periodical-detail" width="160px" height="200px" type="2" :journalItem="item"></journal-item>
                            </div>

                        </div>
                    </div>
                </a-spin>
            </div>
            <div class="journal-article-right">
                <!-- 论文排名 -->
                <template v-if="paperWritingList.length">
                    <common-title :isMore="false" title="论文撰写技巧"></common-title>
                    <div class="mt10">
                        <div @click="videoJumpDetail(item.id)" class="paper-writing y-center mb15" v-for="(item,index) in paperWritingList" :key="index">
                            <img v-if="index == 0" src="~@/assets/images/journal-skill1.png" alt="">
                            <img v-if="index == 1" src="~@/assets/images/journal-skill2.png" alt="">
                            <img v-if="index == 2" src="~@/assets/images/journal-skill3.png" alt="">
                            <img style="width:15px" v-if="index > 2" src="~@/assets/images/journal-cardiovascular.png" alt="">
                            <span :title="item.title" class="ml10 fs16 color333 row-1 t-l" :class="{'color666':index > 2}">{{item.title}}</span>
                        </div>
                    </div>
                </template>
                <div style="margin-top:60px;">
                    <layout-right :config="layoutRightConfig"></layout-right>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import layout from '@/components/layout/index.vue';
import layoutRight from '@/components/layout/layout-right';
import approximations from './approximations.vue'; //期刊约稿
import journalItem from '../../components/journal-item.vue'; //推荐期刊
import commonTitle from '@/components/common-title.vue';
import acrossMediaItem from '@/components/across-media-item.vue'; //推荐论文合集
import surveyItem from '@/components/survey-item'; //有奖调查
import subjectList from '@/components/subject-list';// 学科列表
import { jsonFormat } from '@/utils/jsonFormat.js';
import { videoJumpDetail } from '@/utils/jumpPageMethods'
import { mapState } from 'vuex';
export default {
    name: 'JournalArticle',
    data() {
        return {
            videoJumpDetail,
            value1: "",
            loading: true,
            layoutRightConfig: {
                paper: {
                    show: true, // 是否显示
                    title: '会议论文', // 标题
                    methods: 'post',
                    data: {
                        page: 1,
                        limit: 3
                    },   // 参数条件
                }
            },
            // 期刊约稿列表
            approximationList: [],
            imgList: [], //banner图
            // 推荐期刊
            journalItemList: [],
            // 论文撰写技巧
            paperWritingList: [],
        }
    },
    components: {
        layout,
        layoutRight,
        approximations,
        journalItem,
        commonTitle,
        acrossMediaItem,
        surveyItem,
        subjectList
    },
    computed: {
        ...mapState(['userInfo'])
    },
    created() {
        this.pageInit()

    },
    methods: {
        // 三方小程序体验版提交
        change() {
            console.log(this.value1)
        },
        commitXcx() {
            // let data = {
            //     ext: {
            //         xcxId: 3333333333333333
            //     },
            //     // tabBar: {
            //     //     color: "#999999",
            //     //     selectedColor: "#1696ff",
            //     //     borderStyle: "white",
            //     //     backgroundColor: "#fff",
            //     //     list: [
            //     //         {
            //     //             text: "首页",
            //     //             pagePath: "pages/index/index"
            //     //         }
            //     //     ]
            //     // }
            // }
            let params = {
                appid: "wx39c49a3fcaa30751",
                template_id: this.value1,
                ext_json: '',
                user_version: '1.0.0',
                user_desc: '我是一个描述'
            }
            console.log(params)
            this.request.post('commit', params).then(res => {
                console.log(res)
                if (res.code == 200) {
                    this.request.post('qrcode', {
                        appid: 'wx39c49a3fcaa30751'
                    }).then(res => {
                        console.log(res)
                    })
                }
            })
        },
        // 获取banner图
        getJournalBanner() {
            return this.request.post('GetBanner', { banner_type: 2 })
        },
        // 获取期刊列表
        getPeriodicalList() {
            return this.request.post('PeriodicalList', { page: 1, limit: 4, operate_id: this.userInfo.operate_subject || '' })
        },
        // 获取约稿列表
        getNews() {
            return this.request.post('GetNews', { page: 1, limit: 4, type: 4 })
        },
        // 获取论文撰写技巧
        getPaperList() {
            return this.request.get('GetWonderfulVideo', { type: 2, type_id: 37 })
        },
        handleNavigateEvent(item) {
            if (item.url) {
                router.push({
                    path: item.url,
                })
            } else {
                this.$emit('navigateTo', item);
            }
        },
        // 格式化论文撰写技巧
        paperFormat(data) {
            let result = [];
            for (let key in data) {
                result = result.concat(data[key].value);
            }
            return result.slice(0, 8);
        },
        pageInit() {
            let reqArr = [
                this.getJournalBanner(),
                this.getPeriodicalList(),
                this.getNews(),
                this.getPaperList(),
            ]
            Promise.all(reqArr).then(res => {
                this.imgList = res[0].data
                let periObj = {
                    desc: 'en_name',
                }
                this.journalItemList = jsonFormat(res[1].data.list, periObj)
                this.approximationList = res[2].data.list
                // 格式化论文撰写技巧
                this.paperWritingList = this.paperFormat(res[3].data);

                this.loading = false
            })
        },
    },
}
</script>
<style lang="less" scoped>
.journal-article-left {
    width: 770px;
    min-height: 500px;
    .approximations {
        width: 100%;
        height: 228px;
    }

    .journal-titles {
        width: 100%;
    }

    .jounal-items {
        width: 365px;
    }
}

.journal-article-right {
    width: 398px;

    .paper-writing {
        height: 24px;
        display: flex;
        cursor: pointer;
    }

    .across-media {
        color: #ffa45d;
    }

    .mb65 {
        margin-bottom: 65px;
    }
}
@media screen and (max-width:768px){
    .container{
        display: block;
        margin-top:0;
        .journal-article-left{
            width: 100%;
            .journal-list{
                margin-top: 20px;
                padding: 0 10px;
                .jounal-items{
                    width: 100%;
                    /deep/.maxw{
                        flex:1;
                }
            }
        }
        }
        .journal-article-right{
            width: 100%;
            padding: 0 10px;
            margin-top: 20px;
        }
    }
}
</style>
