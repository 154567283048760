<template>
    <div class="communities-matrix">
        <a-carousel class="matrix-carousel" dotsClass="matrix-dots">
            <div class="matrix-list flex-wrap">
                <div v-for="(item,index) in 8" :key="index" class="matrix-list-carouse y-center" :class="{'x-center':index != 0}">
                    <img :class="index == 0?'matrix-qrcode':'matrix-other'" src="~@/assets/images/footer-qr2.png" alt="">
                    <span class="color333 flex y-center" :class="index==0?'matrix-qrcode-text t-l ml20 strong fs14':'ml10 fs16'">肿瘤科</span>
                </div>
            </div>
            <div class="matrix-list flex-wrap">
                <div v-for="(item,index) in 8" :key="index" class="matrix-list-carouse y-center" :class="{'x-center':index != 0}">
                    <img :class="index == 0?'matrix-qrcode':'matrix-other'" src="~@/assets/images/footer-qr2.png" alt="">
                    <span class="color333 flex y-center" :class="index==0?'matrix-qrcode-text t-l ml20 strong fs14':'ml10 fs16'">心血管内科</span>
                </div>
            </div>
            <div class="matrix-list flex-wrap">
                <div v-for="(item,index) in 8" :key="index" class="matrix-list-carouse y-center" :class="{'x-center':index != 0}">
                    <img :class="index == 0?'matrix-qrcode':'matrix-other'" src="~@/assets/images/footer-qr2.png" alt="">
                    <span class="color333 flex y-center" :class="index==0?'matrix-qrcode-text t-l ml20 strong fs14':'ml10 fs16'">神经内科</span>
                </div>
            </div>
        </a-carousel>
    </div>
</template>
<script>
export default {

}
</script>
<style lang="less" scope>
.communities-matrix {
    width: 100%;
    height: 224px;
    .matrix-carousel {
        width: 100%;
        height: 100%;
        .matrix-dots{
            bottom: 2px;
        }
        .matrix-dots li.slick-active button {
            background: #1e51c9 !important;
            opacity: 1 !important;
        }
        .matrix-dots li button {
            background: #1e51c9 !important;
            opacity: 0.1 !important;
            border-radius: 2.5px !important;
            width: 14px !important;
            height: 5px !important;
        }
        .matrix-list {
            width: 100%;
            height: 224px;
            display: flex !important;
            .matrix-list-carouse:nth-of-type(4n) {
                margin-right: 0 !important;
            }
            .matrix-list-carouse {
                width: 182px;
                height: 88px;
                background: #f8f9fb;
                margin-right: 13px;
                display: flex;
                .matrix-qrcode {
                    width: 88px;
                    height: 88px;
                }
                .matrix-qrcode-text {
                    width: 57px;
                    height: 44px;
                }
                .matrix-other {
                    width: 29px;
                    height: 29px;
                }
            }
        }
    }
}
</style>