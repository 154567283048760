<template>
    <div>
        <div class="approximations flex x-between">
            <!-- :after-change="onChange" -->
            <a-carousel class="approx-carousel">
                <img :src="item.img | urlFilter(250)" v-for="(item,index) in imgList" :key="index" alt="">
            </a-carousel>
            <div class="approximations-artical">
                <common-title title="期刊约稿" class="common-titles" path="/approximation-list"></common-title>
                <div class="art-list" v-for="(item, index) in approximationList" :key="index">
                    <p :title="item.news_title" class="artical-title t-l row-1 fs16 strong" @click="handleNavigateEvent(item)">{{ item.news_title }}</p>
                </div>
            </div>
        </div>
        <div class="artical-img mt40">
            <router-link to="/database/advanced-search"><img src="~@/assets/images/paper-enter.png" alt=""></router-link>
        </div>
    </div>

</template>

<script>
import commonTitle from '@/components/common-title.vue';
export default {
    name: 'approximations',
    props: {
        title: {
            type: String,
            default: '',
        },
        approximationList: {
            type: Array,
            defalut:[],
        },
        imgList: {
            type: Array,
            defalut:[],
        },
    },
    components: {
        commonTitle
    },
    mounted() {
        
    },
    methods:{
        handleNavigateEvent(item) {
            this.$router.push({
                path: '/infomation-detail',
                query: {
                    id:item.id,
                    type:item.type
                }
            })
        }
    },
}
</script>
<style lang="less" scoped>
.approximations-artical {
    width: 347px;
    height: 228px;

    .common-titles {
        width: 100%;
    }
    .art-list{
        width: 100%;
        height: 47px;
        margin-bottom: 0;
        border-bottom: 1px dashed #D8D8D8;
        .artical-title {
            width: 100%;
            height: 100%;
            line-height: 46px;
            cursor: pointer;
        }
    }
    .art-list:last-child {
        border: 0;
    }
}
.artical-img{
    width: 770px;
    height: 180px;
    img{
        width: 100%;
        height: 100%;
    }
}
.approx-carousel{
    width: 403px;
}
@media screen and (max-width:768px){
    .approximations{
        width: 100%;
        display: block;
        .approx-carousel{
            width:calc(100% + 20px);
            margin-left:-10px;
        }
        .approximations-artical{
            width: 100%;
            padding: 0 10px;
            margin-top: 30px;
        }
    }
    .artical-img{
        width: 100%;
        height: auto;
        margin-top: 20px;
        img{
            width: 100%;
            height: auto;
        }
    }
}
</style>
